import { useParams, useSearchParams } from '@solidjs/router';
import { Component, createSignal, For, onMount, Show } from 'solid-js';
import IconGovernmentId from '~/assets/icons/userId.svg';
import brokenImage from '~/assets/images/brokenImage.svg';
import {
  BANK_STATEMENT,
  DATE_OF_BIRTH,
  EMAIL,
  FIRST_NAME,
  GOVERNMENT_ID,
  LAST_NAME,
  PHONE,
  RentalHistoryField,
  SSN,
  UPLOAD_BANK_STATEMENT,
  UPLOAD_GOVERNMENT_ID,
} from '~/assets/strings';
import LabeledGroup from '~/components/common/Inputs/LabeledGroup';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import { TintedSVG } from '~/components/common/TintedSVG';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { DropZone } from '~/components/common/forms/Dropzone';
import { IconX } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { FileDescriptor, FileType } from '~/types/RentalApplication';
import { RemoveRentalApplicationFileUseCase } from '~/use-cases/rental-applications/application-steps/removeRentalApplicationFileUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { SetUnitWithIdUseCase } from '~/use-cases/rental-applications/initUnitFromUrlUseCase';
import { isImage, isPdf } from '~/utils/fileType';
import { uuid } from '~/utils/tools';

export const ApplicantInformationStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const [files, setFiles] = createSignal<FileDescriptor[]>([]);
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);
  const [searchParams] = useSearchParams<{
    unitId: string;
  }>();

  let govIdRef: HTMLInputElement | undefined;
  let bankStatementRef: HTMLInputElement | undefined;
  let firstNameRef: HTMLInputElement | undefined;
  let lastNameRef: HTMLInputElement | undefined;
  let emailRef: HTMLInputElement | undefined;
  let phoneRef: HTMLInputElement | undefined;
  let ssnRef: HTMLInputElement | undefined;
  let dateOfBirthRef: HTMLInputElement | undefined;

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication, isLoading: isUpdatingApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: removeFiles } = useUseCase(RemoveRentalApplicationFileUseCase);
  const { execute: goToNextStep, didSucceed: didSucceedGoingNext } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);
  const { execute: setUnitId } = useUseCase(SetUnitWithIdUseCase);

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
    setUnitId(searchParams.unitId);
  });

  function handleFiles(type: FileType, fileList?: FileList | null) {
    const newImages: FileDescriptor[] = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (!isImage(file) && !isPdf(file)) {
          continue;
        }
        const id = uuid();
        const fileDescriptor: FileDescriptor = {
          id,
          type,
          file,
          name: file.name,
          wasUploaded: false,
          isImage: file.type.includes('image'),
        };
        newImages.push(fileDescriptor);
      }
    }
    setFiles([...files(), ...newImages]);
    updateRentalApplication({ files: files() });
  }

  const removeApplicationFile = async (id: string) => {
    await removeFiles(id);
    setFiles(files().filter((file) => file.id !== id));
    if (govIdRef?.value) {
      govIdRef.value = '';
    }
    if (bankStatementRef?.value) {
      bankStatementRef.value = '';
    }
  };

  const onNext = async () => {
    await validateRentalApplication();
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasApplicantInfoError) {
      return;
    }
    setIsGoingToNextStep(true);
    await goToNextStep({
      currentStep: RentalApplicationSteps.APPLICANT_INFORMATION,
      credentials: { id: params.id, password: params.password },
    });
    if (!didSucceedGoingNext()) {
      setIsGoingToNextStep(false);
    }
  };

  const updateInfo = (fieldName: string) => {
    updateRentalApplication({
      firstName: firstNameRef?.value,
      lastName: lastNameRef?.value,
      email: emailRef?.value,
      phone: phoneRef?.value,
      ssn: ssnRef?.value,
      dateOfBirth: dateOfBirthRef?.value,
    });
    validateRentalApplication(fieldName);
  };

  const maxBirthDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split('T')[0];
  };

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={<div class="flex h-section3 flex-1 grow ">{isUpdatingApplication() ? <ActivityIndicator /> : <ActivityIndicator />}</div>}>
      <div class="flex flex-col">
        <div class="flex-1 overflow-y-auto p-6">
          <div class="grid grid-cols-2 gap-5 py-4">
            <LabeledTextInput
              label={t(FIRST_NAME)}
              required
              error={presentableRentalApplication()?.errors?.firstNameError}
              ref={firstNameRef}
              value={presentableRentalApplication()?.firstName || ''}
              onChange={() => updateInfo(RentalHistoryField.FirstName)}
            />
            <LabeledTextInput
              label={t(LAST_NAME)}
              required
              error={presentableRentalApplication()?.errors?.lastNameError}
              ref={lastNameRef}
              value={presentableRentalApplication()?.lastName || ''}
              onChange={() => updateInfo(RentalHistoryField.LastName)}
            />
            <LabeledTextInput
              label={t(EMAIL)}
              required
              error={presentableRentalApplication()?.errors?.emailError}
              ref={emailRef}
              value={presentableRentalApplication()?.email || ''}
              onChange={() => updateInfo(RentalHistoryField.Email)}
            />
            <LabeledTextInput
              label={t(PHONE)}
              required
              error={presentableRentalApplication()?.errors?.phoneError}
              ref={phoneRef}
              value={presentableRentalApplication()?.phoneNumber || ''}
              onChange={() => updateInfo(RentalHistoryField.Phone)}
              maxLength={10}
            />
            <LabeledTextInput
              label={t(SSN)}
              required
              error={presentableRentalApplication()?.errors?.ssnError}
              ref={ssnRef}
              value={presentableRentalApplication()?.ssn || ''}
              onChange={() => updateInfo(RentalHistoryField.SSN)}
              maxLength={11}
            />
            <LabeledTextInput
              type="date"
              required
              label={t(DATE_OF_BIRTH)}
              error={presentableRentalApplication()?.errors?.dateOfBirthError}
              ref={dateOfBirthRef}
              value={presentableRentalApplication()?.dateOfBirth || ''}
              onChange={() => updateInfo(RentalHistoryField.DateOfBirth)}
              max={maxBirthDate()}
            />
          </div>
          <div class="grid grid-cols-2 gap-5 py-4">
            <LabeledGroup class="col-span-full sm:col-span-1" label={t(GOVERNMENT_ID)}>
              <div class={`flex ${!presentableRentalApplication()?.governmentIdFiles ? 'flex-row' : 'flex-col'} gap-4`}>
                <Show when={presentableRentalApplication()?.governmentIdFiles?.length === 0}>
                  <DropZone
                    class="hover:bg-primary-color/10 flex h-28 cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-primary-color pt-3"
                    activeClass="bg-primary-color/5"
                    onDropFiles={(files?: FileList) => handleFiles(FileType.Identification, files)}
                    onClick={() => govIdRef?.click()}>
                    <TintedSVG svg={IconGovernmentId} color="var(--primary-color)" />
                    <div class="text-sm font-medium text-primary-color"> {t(UPLOAD_GOVERNMENT_ID)}</div>
                    <input
                      type="file"
                      hidden
                      ref={govIdRef}
                      accept="image/*,.pdf"
                      onChange={(e) => handleFiles(FileType.Identification, e.target.files)}
                    />
                  </DropZone>
                </Show>
              </div>
              <Show when={presentableRentalApplication()?.governmentIdFiles}>
                <For each={presentableRentalApplication()?.governmentIdFiles}>
                  {(file) => (
                    <div class="text-center">
                      {file.isImage ? (
                        <div class="relative mx-1 mb-1 inline-block min-h-80 min-w-80 cursor-pointer">
                          <img
                            class="size-full cursor-pointer rounded-lg border border-black bg-white object-cover"
                            src={file.path}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = brokenImage;
                            }}
                          />
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      ) : (
                        <div class="relative rounded-md bg-input py-2">
                          <div class="w-[calc(100%-32px)] truncate px-2 text-sm font-medium">{file.name}</div>
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </For>
              </Show>
            </LabeledGroup>
            <LabeledGroup class="col-span-full sm:col-span-1" label={t(BANK_STATEMENT)}>
              <div class={`flex ${!presentableRentalApplication()?.bankStatementFiles ? 'flex-row' : 'flex-col'} gap-4`}>
                <Show when={presentableRentalApplication()?.bankStatementFiles?.length === 0}>
                  <DropZone
                    class="hover:bg-primary-color/10 flex h-28 cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-primary-color pt-3"
                    activeClass="bg-primary-color/5"
                    onDropFiles={(files?: FileList) => handleFiles(FileType.BankStatement, files)}
                    onClick={() => bankStatementRef?.click()}>
                    <TintedSVG svg={IconGovernmentId} color="var(--primary-color)" />
                    <div class="text-sm font-medium text-primary-color"> {t(UPLOAD_BANK_STATEMENT)}</div>
                    <input
                      type="file"
                      hidden
                      ref={(element) => (bankStatementRef = element)}
                      accept="image/*,.pdf"
                      onChange={(e) => handleFiles(FileType.BankStatement, e.target.files)}
                    />
                  </DropZone>
                </Show>
              </div>
              <Show when={presentableRentalApplication()?.bankStatementFiles}>
                <For each={presentableRentalApplication()?.bankStatementFiles}>
                  {(file) => (
                    <div class="text-center">
                      {file.isImage ? (
                        <div class="relative mx-1 mb-1 inline-block cursor-pointer">
                          <img
                            class="size-full cursor-pointer rounded-lg border border-black bg-white object-cover"
                            src={file.path}
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = brokenImage;
                            }}
                          />
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      ) : (
                        <div class="rounded-md bg-input py-2">
                          <div class="w-[calc(100%-32px)] truncate px-2 text-sm font-medium">{file.name}</div>
                          <div
                            class="absolute right-4 top-5 -mr-2 -mt-3 flex size-6 items-center justify-center rounded-full border border-border-level01 bg-white  hover:bg-inputbox-bg"
                            onClick={() => removeApplicationFile(file.id)}>
                            <IconX aria-label="Close" class="size-4 text-text-level03" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </For>
              </Show>
            </LabeledGroup>
          </div>
        </div>
      </div>
      <StepFooter onNext={onNext} />
    </Show>
  );
};
