import { MagicUseCase } from '~/use-cases/magicUseCase';

export class RemoveRentalApplicationFileUseCase extends MagicUseCase {
  protected async runLogic(fileId: string) {
    const rentalApplication = this.getState().user.rentalApplication.application;
    if (!rentalApplication) {
      return;
    }
    const { newFiles, files } = rentalApplication;
    if (newFiles) rentalApplication.newFiles = newFiles.filter((file) => file.id !== fileId);
    if (files) rentalApplication.files = files.filter((file) => file.fileId !== fileId);
  }
}
