import { Component, JSX, createSignal, splitProps } from 'solid-js';
import { cn } from '~/utils/classnames';

type DropZoneProps = JSX.HTMLAttributes<HTMLDivElement> & {
  activeClass?: string;
  onDropFiles?: (files?: FileList) => void;
  onClick?: (ev?: MouseEvent) => void;
};

export const DropZone: Component<DropZoneProps> = (props) => {
  const [active, setActive] = createSignal<boolean>(false);
  const [, tagProps] = splitProps(props, ['activeClass']);

  function handleDrag(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setActive(true);
    } else if (e.type === 'dragleave') {
      setActive(false);
    }
  }

  function handleDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setActive(false);

    props.onDropFiles && props.onDropFiles(e.dataTransfer?.files);
  }

  return (
    <div
      {...tagProps}
      class={cn(props.class, active() && props.activeClass)}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    />
  );
};
