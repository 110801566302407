import { ApplicantInformationStep } from '~/pages/rental-application/steps/ApplicantInformationStep';

const ApplicantInformation = () => {
  return (
    <div class="relative flex-1 grow flex-col">
      <ApplicantInformationStep />
    </div>
  );
};

export default ApplicantInformation;
