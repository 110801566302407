import { companyUnitListingGateway } from '~/gateways/companyUnitListingGateway';
import { MagicUseCase } from '../magicUseCase';

export class SetUnitWithIdUseCase extends MagicUseCase {
  protected async runLogic(unitId: string) {
    const application = this.getState().user.rentalApplication.application;

    if (application && unitId && !(application.interestedUnits && application.interestedUnits.length > 0)) {
      application.interestedUnits = [unitId];
      const unit = await companyUnitListingGateway.getUnitListingDetail(unitId);
      if (unit) {
        this.getState().user.unitsListing.preSelectUnits = [unit];
      }
    }
  }
}
